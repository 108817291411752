<template>
    <AppMasterVue >
        <PageTitle :title="title" :breadcrumbs="breadcrumbs"> </PageTitle>
        <section class="section transaction-detail">
            <div class="card" v-if="transactionDisbursement">
                <div class="row">
                    <div class="col-xl-6 left-content">
                        <h5 class="card-title">{{ $t('Label.TransactionInfo') }}</h5>
            
                        <div class="row">
                            <div class="col-lg-4 col-md-5 label">{{ $t('Transaction.OrderCode') }}</div>
                            <div class="col-lg-8 col-md-7">{{ transactionDisbursement.order_code }}</div>
                        </div>
            
                        <div class="row">
                            <div class="col-lg-4 col-md-5 label">{{ $t('Transaction.RequestCode') }}</div>
                            <div class="col-lg-8 col-md-7">{{ transactionDisbursement.request_id }}</div>
                        </div>
            
                        <div class="row">
                            <div class="col-lg-4 col-md-5 label">{{ $t('Transaction.CreatedAt') }}</div>
                            <div class="col-lg-8 col-md-7">{{ formatDate(transactionDisbursement.created_at) }}</div>
                        </div>

                        <div class="row">
                            <div class="col-lg-4 col-md-5 label">{{ $t('Transaction.UpdatedAt') }}</div>
                            <div class="col-lg-8 col-md-7">{{ formatDate(transactionDisbursement.report_date) }}</div>
                        </div>
            
                        <div class="row">
                            <div class="col-lg-4 col-md-5 label">{{ $t('Transaction.PaymentValue') }}</div>
                            <div class="col-lg-8 col-md-7">{{ (showMoneyFormat(transactionDisbursement.amount) + " " + transactionDisbursement.currency) }}</div>
                        </div>
            
                        <div class="row">
                            <div class="col-lg-4 col-md-5 label">{{ $t('Transaction.ChargePaymentFee') }}</div>
                            <div class="col-lg-8 col-md-7">{{ isset(transactionDisbursement.receive_payment_fee) ? (showMoneyFormat(transactionDisbursement.receive_payment_fee) + " " + transactionDisbursement.currency) : '—' }}</div>
                        </div>
            
                        <div class="row">
                            <div class="col-lg-4 col-md-5 label">{{ $t('Transaction.ChargeProcessingFee') }}</div>
                            <div class="col-lg-8 col-md-7">{{ isset(transactionDisbursement.receive_processing_fee) ? (showMoneyFormat(transactionDisbursement.receive_processing_fee) + " " + transactionDisbursement.currency) : '—' }}</div>
                        </div>
            
                        <div class="row">
                            <div class="col-lg-4 col-md-5 label">{{ $t('Transaction.Status') }}</div>
                            <div class="col-lg-8 col-md-7">{{ isset(transactionDisbursement.status) ? $t(showStatus(transactionDisbursement.status)) : '—' }}</div>
                        </div>
                    </div>
        
                    <div class="col-xl-6 left-content">
                        <h5 class="card-title">{{ $t('Label.CardInfo') }}</h5>
            
                        <div class="row">
                            <div class="col-lg-4 col-md-5 label ">{{ $t('Disbursement.Bank') }}</div>
                            <div class="col-lg-8 col-md-7">{{ transactionDisbursement.order_disbursement.bank_code }}</div>
                        </div>
            
                        <div class="row">
                            <div class="col-lg-4 col-md-5 label">{{ $t('Disbursement.AccountNo') }}</div>
                            <div class="col-lg-8 col-md-7">{{ transactionDisbursement.order_disbursement.account_no }}</div>
                        </div>
            
                        <div class="row">
                            <div class="col-lg-4 col-md-5 label">{{ $t('Disbursement.AccountName') }}</div>
                            <div class="col-lg-8 col-md-7">{{ transactionDisbursement.order_disbursement.account_name }}</div>
                        </div>
            
                        <div class="row">
                            <div class="col-lg-4 col-md-5 label">{{ $t('Disbursement.Content') }}</div>
                            <div class="col-lg-8 col-md-7">{{ transactionDisbursement.order_disbursement.description }}</div>
                        </div>
                    </div>
                </div>
    
                <div class="row btn-content">
                    <div class="text-center">
                        <a-button onclick="history.back()">{{ $t('Button.Back') }}</a-button>
                    </div>
                </div>
            </div>
        </section>
    </AppMasterVue>
</template>
  
<script>

import AppMasterVue from '../../../layout/AppMaster.vue'
import PageTitle from '@/components/PageTitle'
import { mapGetters } from 'vuex';
import helper from "../../../until/helper";

export default {
    name: 'TransactionDisbursementDetail',
    components: {
        AppMasterVue,
        PageTitle
    },
    data() {
        return {
            title: 'Title.TransactionDisbursementDetail',
            breadcrumbs: [
                {
                    name: 'Title.Home',
                    routeName: 'Home',
                    active: true,
                },
                {
                    name: 'Sidebar.TransactionDisbursementManagement',
                    routeName: null,
                    active: false
                },
                {
                    name: 'Sidebar.TransactionDisbursement',
                    routeName: 'TransactionDisbursementList',
                    active: true
                },
                {
                    name: 'Title.TransactionDisbursementDetail',
                    routeName: 'TransactionDisbursementDetail',
                    active: true
                }
            ]
        }
    },
    computed: {
        ...mapGetters({
            transactionDisbursement: 'transaction/getTransactionDisbursementDetail',
            user: 'authentications/getUserLogin',
        }),
    },
    mounted() {
        this.$store.dispatch('transaction/transactionDisbursementDetail', { orderCode : this.$route.params.order_code });
    },
    methods: {
        formatDate: helper.formatDate,
        showMoneyFormat: helper.showMoneyFormat,
        isset: helper.isset,
        showStatus: helper.showStatus
    }
}
</script>
  